import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Header from "./components/Header";
import JobCard from "./components/JobCard";
import JobDetails from "./components/JobDetails";

const App = () => {
  type JobCard = {
    employmentType: string;
    jobTitle: string;
    path: string;
    location?: string;
    workStyle?: string;
    salaryRange?: string;
  };

  const jobCards: JobCard[] = [
    {
      employmentType: "正社員",
      jobTitle: "営業担当者",
      path: "/jobs/sales",
      location: "関東エリア",
      workStyle: "出社・リモート",
      salaryRange: "見込み年収600万円",
    },
  ];

  type JobInPrepCard = {
    employmentType: string;
    jobTitle: string;
    location: string;
    workStyle: string;
    salaryRange: string;
  };

  const jobInPrepCards: JobInPrepCard[] = [
    {
      employmentType: "パートタイム",
      jobTitle: "営業インターン",
      location: "つくば市",
      workStyle: "出社・リモート",
      salaryRange: "時給1000円〜",
    },
    {
      employmentType: "正社員",
      jobTitle: "マーケティングディレクター",
      location: "日本",
      workStyle: "出社・リモート",
      salaryRange: "600万円〜1000万円",
    },
    {
      employmentType: "正社員",
      jobTitle: "カスタマーサクセス",
      location: "関東エリア",
      workStyle: "リモートOK",
      salaryRange: "300万円",
    },
    {
      employmentType: "正社員",
      jobTitle: "ソフトウェアエンジニア",
      location: "日本",
      workStyle: "リモートOK",
      salaryRange: "600万円〜1000万円",
    },
  ];

  const location = useLocation();

  return (
    <div>
      <Header />
      {location.pathname === "/" && (
        <>
          <Container
            sx={{
              height: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5">
              Let's work together!
            </Typography>
          </Container>
          <Container>
            <Typography mb={1} variant="h6">
              募集職種
            </Typography>
            {jobCards.map((job, index) => (
              <Link
                to={job.path}
                key={index}
                style={{ textDecoration: "none" }}
              >
                <JobCard {...job} />
              </Link>
            ))}
          </Container>
          <Container sx={{ mt: 5 }}>
            <Typography mb={1} variant="h6">
              採用準備中 (Coming Soon)
            </Typography>
            {jobInPrepCards.map((job, index) => (
              <JobCard {...job} />
            ))}
          </Container>
        </>
      )}
      <Routes>
        <Route path="/jobs/:jobPath" element={<JobDetails />} />
      </Routes>
    </div>
  );
};

export default App;
