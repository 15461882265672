import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#bf3939',
      light: '#ed6f6f',
    },
    secondary: {
      main: '#07a68b',
      light: '#baf1e4'
    },
    black: {
      main: "#000000"
    }
  },
});

export default theme;
