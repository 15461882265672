import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActionArea,
  Chip,
  Button,
} from "@mui/material";

interface JobCardProps {
  employmentType: string;
  jobTitle: string;
  path?: string;
  location?: string;
  workStyle?: string;
  salaryRange?: string;
}

export default function JobCard({
  employmentType,
  jobTitle,
  path,
  location,
  workStyle,
  salaryRange,
}: JobCardProps) {
  const content = (
    <CardContent>
      <Typography variant="h6" fontWeight={600} color={path ? "text.primary" : "text.disabled"}>
        {jobTitle}
      </Typography>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          {employmentType && (
            <Chip label={`🕒 ${employmentType}`} color="default" disabled={!path} />
          )}
          {location && <Chip label={`📍 ${location}`} color="default" disabled={!path} />}
          {workStyle && <Chip label={`🏢 ${workStyle}`} color="default" disabled={!path} />}
          {salaryRange && <Chip label={`💰 ${salaryRange}`} color="default" disabled={!path} />}
        </Box>
        {path && (
          <Button variant="contained" color="primary" href={path}>
            <b>詳細を見る</b>
          </Button>
        )}
      </Box>
    </CardContent>
  );
  return (
    <Box mb={1}>
      <Card
        sx={{
          borderRadius: "10px",
          transition: "border-color 0.2s ease",
          "&:hover": {
            ...(path
              ? { borderColor: "black !important", cursor: "pointer" }
              : {}),
          }
        }}
        variant="outlined"
      >
        {path ? (
          <CardActionArea>
            {content}
          </CardActionArea>
        ) : content}
      </Card>
    </Box>
  );
}
