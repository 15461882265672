import React, { useState, useEffect, useRef } from "react";
import { Container, Typography, Box, Button, Divider } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface JobPost {
  id: string;
  title: string;
  employmentType: string;
  location: string;
  workStyle: string;
  salaryRange: string;
  description: string;
  responsibilities: string[];
  requirements: string[];
  preferred: string[];
  benefits: string[];
  details: {
    location: string[];
    workingHours: string[];
    probation: string;
    holidays: string[];
    selectionProcess: string[];
    salaryDetails: string[];
  };
}

const jobsData: Record<string, JobPost> = {
  sales: {
    id: "sales",
    title: "営業担当者",
    employmentType: "正社員",
    location: "関東エリア",
    workStyle:
      "基本的に在宅および医療機関オンサイトでの勤務\n※必要に応じ出社あり",
    salaryRange: "見込み年収600万円",
    description:
      "医療機関の業務を支援するクラウドサービス「Ambii Web問診・Web予約」、患者が自身に適した医療機関を受診できるよう支援する「Ambii Platform」を医療機関へ普及させる営業業務をお任せします。",
    responsibilities: [
      "商談獲得活動、プロダクトの提案業務、契約締結後のプロダクト導入業務などの上記の自社プロダクトを医療機関に販売するためのあらゆる業務を担っていただきます。\n\n商談獲得活動では、医療機関リストへの架電や医療機関のHPからのメール/フォーム営業によるアポ獲得などを行っていただきます。\nその他、自社LPやWEB広告、DM広告などのチャネルからの商談獲得がございます。\n\n提案業務では、医療機関の現地へ訪問、またはオンライン会議で医療機関の医師・事務長・看護師などに向けて提案していただき、契約締結まで担当いただきます。\n\n商談成立後は、契約書の締結業務から、プロダクトの初期導入作業などを実施いただきます。",
    ],
    requirements: [
      "法人営業経験（目安：2年以上）",
      "Ambiiのミッションへの共感",
      "関東の病院/クリニックに公共交通機関などを使用して現地訪問可能",
      "ネイティブレベルの日本語力",
    ],
    preferred: [
      "病院/クリニック営業経験",
      "ビジネスレベル以上の英語力",
      "海外在住経験",
    ],
    benefits: [
      "近年の医療業界における社会課題に関して、医療機関と患者双方の課題解決を目指すビジネスを担う、社会貢献を実感できるポジションです。",
      "営業活動に専念する正社員として1人目のメンバーとなるポジションのため、将来的に営業メンバーの管理や営業戦略の立案などを担当する営業責任者の候補となるポジションです。",
      "日本以外でも、すでに台湾でのサービスを展開しており、また今後はアジアを中心にグローバルにサービスを展開するため、本人の意向次第でグローバルなキャリアに挑戦できるポジションとなっております。",
    ],
    details: {
      location: [
        "茨城県つくば市吾妻２丁目5−１ つくばスタートアップパーク",
        "【アクセス】",
        "つくばエクスプレス線「つくば駅」　徒歩5分",
        "※必要に応じ出社いただく場合がございますが、基本的に在宅および医療機関オンサイトでの勤務となります。",
      ],
      workingHours: [
        "09:00〜18:00（うち休憩1時間）",
        "※上記を基準勤務時間としますが、事前申請/承認により、勤務時間をご自身で自由に調整いただけます。",
        "※医療機関の対応のため、18時以降も勤務が発生する場合がございます。",
      ],
      probation: "3ヶ月",
      holidays: [
        "完全週休2日制",
        "祝祭日",
        "年末年始休暇",
        "夏季休暇",
        "有給休暇",
      ],
      salaryDetails: [
        "見込み年収：600万円〜（ベース給 360万円＋インセンティブ給）",
        "（例）年収720万円（目標100%達成時）\n  - ベース：360万\n  - インセンティブ：360万",
        "※インセンティブ給に上限はありません",
      ],
      selectionProcess: ["①履歴書・職務経歴書の提出", "②1次面接", "③最終面接"],
    },
  },
};

export default function JobDetails() {
  const [job, setJob] = useState<JobPost | null>(null);
  const navigate = useNavigate();
  const formRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const jobId = window.location.pathname.split("/").pop();
    if (jobId) {
      const selectedJob = jobsData[jobId];
      if (selectedJob) {
        setJob(selectedJob);
      }
    }
  }, []);

  const handleApply = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  if (!job) {
    return;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Box sx={{ mb: 4 }}>
        <Button
          variant="text"
          size="medium"
          sx={{ mb: 4 }}
          onClick={() => navigate("/")}
        >
          <ChevronLeft sx={{ mr: 0.5 }} />
          <u>戻る</u>
        </Button>
        <Typography variant="h4" component="h1" gutterBottom fontWeight={600}>
          {job.title}
        </Typography>

        <Box sx={{ color: "text.secondary", mb: 3 }}>
          <Typography>🕒 {job.employmentType}</Typography>
          <Box sx={{ my: 1 }}>
            <Typography>📍 {job.location}</Typography>
          </Box>
          <Typography>🏢 {job.workStyle}</Typography>
          <Box sx={{ my: 1 }}>
            <Typography>💸 {job.salaryRange}</Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ mb: 4 }}
          onClick={handleApply}
        >
          <b>応募する</b>
        </Button>
        <Divider sx={{ mb: 3 }} />
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom fontWeight={600}>
            職務内容
          </Typography>
          <Typography>{job.description}</Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom fontWeight={600}>
            具体的な業務内容
          </Typography>
          <Box>
            {job.responsibilities.map((responsibility, index) => (
              <Typography key={index} sx={{ mb: 2 }}>
                {responsibility.split("\n\n").map((line, lineIndex) => (
                  <Box key={lineIndex} mt={2}>
                    {line}
                  </Box>
                ))}
              </Typography>
            ))}
          </Box>
        </Box>
        <Divider sx={{ mb: 3 }} />
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom fontWeight={600}>
            必須スキル・経験
          </Typography>
          <ul>
            {job.requirements.map((requirement, index) => (
              <li key={index}>
                <Typography>{requirement}</Typography>
              </li>
            ))}
          </ul>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom fontWeight={600}>
            歓迎スキル・経験
          </Typography>
          <ul>
            {job.preferred.map((skill, index) => (
              <li key={index}>
                <Typography>{skill}</Typography>
              </li>
            ))}
          </ul>
        </Box>
        <Divider sx={{ mb: 3 }} />
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom fontWeight={600}>
            本ポジションで働く魅力
          </Typography>
          <ul>
            {job.benefits.map((benefit, index) => (
              <li key={index}>
                <Typography>{benefit}</Typography>
              </li>
            ))}
          </ul>
        </Box>
        <Divider sx={{ mb: 3 }} />
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom fontWeight={600}>
            勤務地
          </Typography>
          {job.details.location.map((detail, index) => (
            <Typography key={index}>{detail}</Typography>
          ))}
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom fontWeight={600}>
            給与
          </Typography>
          {job.details.salaryDetails.map((detail, index) => (
            <Typography key={index}>{detail}</Typography>
          ))}
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom fontWeight={600}>
            勤務時間
          </Typography>
          {job.details.workingHours.map((detail, index) => (
            <Typography key={index}>{detail}</Typography>
          ))}
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom fontWeight={600}>
            試用期間
          </Typography>
          <Typography>{job.details.probation}</Typography>
        </Box>

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom fontWeight={600}>
            休日・休暇
          </Typography>
          <ul>
            {job.details.holidays.map((holiday, index) => (
              <li key={index}>
                <Typography>{holiday}</Typography>
              </li>
            ))}
          </ul>
        </Box>

        <Divider sx={{ mb: 3 }} />

        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom fontWeight={600}>
            選考フロー
          </Typography>
          {job.details.selectionProcess.map((step, index) => (
            <Typography key={index}>{step}</Typography>
          ))}
        </Box>
      </Box>

      <iframe
        title="Job Application Form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSfTB8HBECXIOmI_hwyZ_ZR6QYMzanAmzrfRJUCCubKHURC6gw/viewform?embedded=true"
        width="100%"
        height="900"
        style={{
          border: "1px solid #ddd",
          borderRadius: "10px",
          backgroundColor: "#fff8f8",
        }}
        ref={formRef}
      >
        Loading���
      </iframe>
    </Container>
  );
}
