import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
const ambiiLogo = process.env.PUBLIC_URL + "/images/ambii-logo.png";

export default function Header() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  return (
    <AppBar
      position="static"
      color="transparent"
      sx={{ borderBottom: "1px solid #ddd", boxShadow: "none" }}
    >
      <Container maxWidth="lg">
        <Toolbar
          sx={{
            height: isMobile ? "60px" : "80px",
            display: "flex",
            justifyContent: "space-between",
            padding: "0 !important",
          }}
        >
          <Box display="flex" alignItems="flex-end">
            <Button
              color="inherit"
              aria-label="menu"
              sx={{ display: "flex", alignItems: "center" }}
              onClick={() => navigate("/")}
            >
              <img
                src={ambiiLogo}
                alt="Ambii Logo"
                style={{ height: isMobile ? "18px" : "23px" }}
              />
              <Typography
                mt={isMobile ? 0.7 : 1}
                ml={1.2}
                variant={isMobile ? "body1" : "h6"}
                color="textPrimary"
                className="text-center"
                sx={{ textTransform: "none" }}
              >
                Careers
              </Typography>
            </Button>
          </Box>
          {!isMobile && (
            <Button
              variant="text"
              color="inherit"
              sx={{ px: 2 }}
              href="https://ambii.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              会社のホームページへ
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
